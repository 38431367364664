import React from "react";
import { Link } from 'gatsby';

import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import Section from "../components/section";

const PrivacyPolicy = () => {
    return <Layout staticHeader>
        <SEO title="Política de privacidad" description=""/>
        
        <Section title="Política de privacidad">
          <div class="container">
            
            <p>De conformidad con lo establecido en la Ley Org&aacute;nica 15/1999, de 13 de diciembre, de Protecci&oacute;n de Datos de Car&aacute;cter Personal, ADVENTUREES ALLIANCE S.L. (en adelante ADVENTUREES), empresa espa&ntilde;ola, domiciliada en C/ Santa Teresa de Jornet e Ibars, 11, 38004 S/C de Tenerife, por medio de la presente Pol&iacute;tica de Privacidad, informa de que los datos de car&aacute;cter personal que voluntariamente le sean facilitados por los usuarios de internet (en adelante, el Usuario o los Usuarios) a trav&eacute;s de los servicios y distintos formularios ofrecidos en la p&aacute;gina web do.adventurees.com (en adelante, el Sitio Web), quedar&aacute;n incorporados y ser&aacute;n tratados en los ficheros titularidad de ADVENTUREES bajo su responsabilidad, con la finalidad de poder contactar con los Usuarios, gestionar las solicitudes de prestaci&oacute;n de servicios as&iacute; como responder a cualquier consulta que se le formule.</p>
            <p>La presente Pol&iacute;tica de Privacidad tiene por objeto dar a conocer el modo en que ADVENTUREES recaba, trata y protege los datos de car&aacute;cter personal a trav&eacute;s del Sitio Web, a fin de que los Usuarios que utilicen el Sitio Web determinen libre y voluntariamente si desean facilitar sus datos personales a trav&eacute;s de los formularios habilitados en su caso.</p>
            <p>El acceso, uso y/o registro en el Sitio Web por el Usuario implica que &eacute;ste acepta expresamente en su totalidad, y se obliga a cumplir por completo, con lo establecido en la presente Pol&iacute;tica de Privacidad as&iacute; como en las disposiciones contenidas en el <Link to="legal-advice">Aviso Legal</Link>. Por lo tanto, los Usuarios deben leer atentamente la presente Pol&iacute;tica de Privacidad en cada una de las ocasiones que se propongan utilizar el Sitio Web, ya que &eacute;sta puede sufrir modificaciones en funci&oacute;n de novedades o exigencias legislativas y jurisprudenciales o por necesidades de negocio.</p>
            <p>La presente Pol&iacute;tica de Privacidad ser&aacute; v&aacute;lida &uacute;nicamente para los datos de car&aacute;cter personal obtenidos en el Sitio Web, no siendo aplicable para aquella informaci&oacute;n recabada por terceros en otras p&aacute;ginas web, incluso si &eacute;stas se encuentran enlazadas por el Sitio Web.</p>
            <p>El Usuario ser&aacute; el &uacute;nico responsable de la veracidad y exactitud de los datos aportados. S&oacute;lo podr&aacute;n ser Usuarios los mayores de 18 a&ntilde;os. ADVENTUREES no recopila intencionadamente Informaci&oacute;n Personal de visitantes menores de 18 a&ntilde;os.</p>
            <h3>1. Datos recogidos a trav&eacute;s de formularios web</h3>
            <p>El Usuario podr&aacute; remitir a ADVENTUREES sus datos de car&aacute;cter personal a trav&eacute;s de los distintos formularios que a tal efecto aparecen incorporados en el Sitio Web.</p>
            <p>Dichos formularios incorporan un texto legal en materia de protecci&oacute;n de datos personales que da cumplimiento a las exigencias establecidas en la Ley Org&aacute;nica 15/1999, de 13 de diciembre, de Protecci&oacute;n de Datos de Car&aacute;cter Personal, y en su Reglamento de Desarrollo, aprobado por el Real Decreto 1720/2007, de 21 de diciembre.</p>
            <h3>2. Datos recogidos a trav&eacute;s de Cookies</h3>
            <p>Se advierte al Usuario que la URL do.adventurees.com emplea cookies, tanto t&eacute;cnicas como de sesi&oacute;n, an&aacute;lisis y personalizaci&oacute;n. Las mismas son peque&ntilde;os ficheros de datos que se generan en el ordenador del Usuario y que permiten conocer informaci&oacute;n que ayuda a personalizar los contenidos que los Usuarios visionan. Los datos que las mismas pueden recabar:</p>
            <ul>
              <li>La fecha y hora de la &uacute;ltima vez que el Usuario visit&oacute; el Sitio Web.</li>
              <li>El dise&ntilde;o de contenidos que el Usuario escogi&oacute; en su primera visita Al Sitio Web.</li>
              <li>Elementos de seguridad que intervienen en el control de acceso a las &aacute;reas restringidas.</li>
              <li>Otros datos</li>
            </ul>
            <p>ADVENTUREES desea ofrecer sus servicios de manera &oacute;ptima por lo que la aceptaci&oacute;n de las cookies ayuda a obtener datos m&aacute;s precisos que permiten mejorar el contenido y el dise&ntilde;o del Sitio Web para adaptarlo a las preferencias de los Usuarios.</p>
            <p>A trav&eacute;s de Cookies solo se recoge y conserva la siguiente informaci&oacute;n acerca de los visitantes del Sitio Web:</p>
            <ul>
              <li>El nombre de dominio del proveedor (PSI) que les da acceso a la red.</li>
              <li>La fecha y la hora del acceso al Sitio Web. Ello permite averiguar las horas de m&aacute;s afluencia, y hacer los ajustes precisos para evitar problemas de saturaci&oacute;n en horas punta.</li>
              <li>La direcci&oacute;n de Internet desde la que parti&oacute; el link que dirige al Sitio Web. Gracias a este dato, se puede conocer la efectividad de los distintos banners y enlaces que apuntan a su servidor, con el fin de potenciar los que ofrezcan mejores resultados.</li>
              <li>El n&uacute;mero de visitantes diario de cada secci&oacute;n. Ello permite conocer las &aacute;reas de m&aacute;s &eacute;xito y aumentar y mejorar su contenido, con el fin de que los Usuarios obtengan un resultado m&aacute;s satisfactorio.</li>
            </ul>
            <p>Dicha informaci&oacute;n es totalmente an&oacute;nima, y en ning&uacute;n caso puede ser asociada a un Usuario concreto e identificado.</p>
            <p>En todo caso, el Usuario tiene la opci&oacute;n de impedir la generaci&oacute;n de cookies, mediante la selecci&oacute;n de la correspondiente opci&oacute;n en su programa Navegador. Para conocer m&aacute;s sobre la Pol&iacute;tica de cookies pinche <Link to="cookies-policy">aquí</Link>.</p>
            <h3>3. P&aacute;ginas de terceros</h3>
            <p>El Sitio Web puede tener publicidad, links u otro tipo de contenidos que enlacen o redirijan a p&aacute;ginas y servicios de socios, proveedores, anunciantes, patrocinadores o licenciadores de ADVENTUREES y otras terceras partes.</p>
            <p>ADVENTUREES no controla el contenido o los enlaces que aparecen en estas p&aacute;ginas y no es responsable por las pr&aacute;cticas utilizadas por estas p&aacute;ginas web que lleven o deriven del Sitio Web. Estas otras p&aacute;ginas y servicios pueden tener su propia pol&iacute;tica de privacidad y su propia pol&iacute;tica de atenci&oacute;n al cliente. La b&uacute;squeda o interacci&oacute;n en cualquier otra p&aacute;gina web, incluidas aquellas que tienen un enlace al Sitio Web, est&aacute;n sujetas a los t&eacute;rminos y condiciones de esa web, no siendo en ning&uacute;n caso ADVENTUREES responsable.</p>
            <h3>4. Cl&aacute;usula de privacidad</h3>
            <p>Esta declaraci&oacute;n tiene como finalidad informar a los Usuarios de la Pol&iacute;tica general de Privacidad y Protecci&oacute;n de Datos Personales seguida por ADVENTUREES.</p>
            <p>A su vez, informamos al Usuario que ADVENTUREES realiza el tratamiento de los datos con la finalidad de poder dar acceso personalizado a la cuenta asociada a la direcci&oacute;n e-mail y contrase&ntilde;a que haya seleccionado para el Sitio Web. Para ello, actuar&aacute; de forma proporcionada, obteniendo datos que sean pertinentes, enviando solo comunicaciones comerciales a qui&eacute;n as&iacute; lo haya permitido de manera expresa en los formularios arriba mencionados.</p>
            <h3>5. Secreto y seguridad de los datos</h3>
            <p>ADVENTUREES se compromete a cumplir con el deber de secreto respecto a los datos de car&aacute;cter personal y al deber de tratarlos con confidencialidad. A su vez, dichos datos ser&aacute;n guardados adoptando las medidas de &iacute;ndole t&eacute;cnica y organizativa necesarias que garanticen la seguridad de los datos de car&aacute;cter personal. Todo ello, con la finalidad de evitar su alteraci&oacute;n, p&eacute;rdida, tratamiento o acceso no autorizado, teniendo siempre en cuenta el estado de la tecnolog&iacute;a, de acuerdo con lo establecido por el RLOPD.</p>
            <h3>6. Comunicaci&oacute;n de datos y env&iacute;o de comunicaciones comerciales</h3>
            <p>El Usuario consiente que ADVENTUREES pueda comunicar datos a terceros, siempre que se trate de empresas colaboradoras o proveedoras de servicios que tengan conexi&oacute;n con el desarrollo del modelo de negocio y la operatividad del Sitio Web.</p>
            <p>En particular, El Usuario queda informado y acepta expresamente que sus datos podr&aacute;n ser cedidos a las entidades que a continuaci&oacute;n se enumeran, as&iacute; como a las sociedades filiales de estas:</p>
            <ul>
              <li>ADVENTUREROS CROWDFUNDING PLATAFORMA DE FINANCIACI&Oacute;N PARTICIPATIVA, Plataforma de Financiaci&oacute;n Participativa que cumple los requisitos exigidos por la Ley 5/2015, de fomento de la financiaci&oacute;n empresarial, con la finalidad de poder canalizar inversiones en aquellos casos en que se requiera por el Usuario. La sociedad est&aacute; domiciliada en la Calle Santa Teresa de Jornet e Ibars, n&uacute;mero 11, Santa Cruz de Tenerife, 38004 (Espa&ntilde;a), identificada con C.I.F. B76632793.</li>
              <li>LEETCHI Corp, S.A., nombre comercial MangoPay, entidad autorizada por el Banco de Espa&ntilde;a para prestar el servicio de Pasarela de Pago. La sociedad est&aacute; domiciliada en 14 Rue Aldringen, L-1118 Luxembourg, con n&uacute;mero de identificaci&oacute;n B173459.</li>
            </ul>
            <p>El Usuario acepta recibir comunicaciones comerciales por correo electr&oacute;nico u otros medios de las organizaciones anteriormente mencionadas.</p>
            <h3>7. Tratamiento de datos de menores de edad</h3>
            <p>El Sitio Web va principalmente dirigido a Usuarios mayores de edad. Sin embargo, con el objetivo de cumplir con el Real Decreto 1720/2007, de 21 de diciembre, por el que se aprueba el Reglamento de desarrollo de la Ley Org&aacute;nica 15/1999, de 13 de diciembre, de protecci&oacute;n de datos de car&aacute;cter personal, ADVENTUREES informa que para proceder al tratamiento de datos de menores de 14 a&ntilde;os requerir&aacute; el consentimiento de los padres o tutores.</p>
            <p>Corresponde a ADVENTUREES articular los procedimientos que garanticen que se ha comprobado de modo efectivo la edad del menor, y la autenticidad del consentimiento prestado en su caso, por lo padres, tutores o representantes legales, en caso de sospechar que es menor de catorce a&ntilde;os.</p>
            <h3>8. Derechos de Acceso, Rectificaci&oacute;n, Oposici&oacute;n y Cancelaci&oacute;n (ARCO)</h3>
            <p>Cualquier Usuario del Sitio Web de ADVENTUREES podr&aacute; en cualquier momento ejercer sus <strong>Derechos de acceso, rectificaci&oacute;n, cancelaci&oacute;n y</strong> <strong>oposici&oacute;n (ARCO) </strong>en los t&eacute;rminos establecidos en la Ley Org&aacute;nica 15/1999dirigi&eacute;ndose al responsable del fichero en la siguiente direcci&oacute;n: info@adventurees.com</p>
          </div>
        </Section>
    </Layout>
}

export default PrivacyPolicy;
